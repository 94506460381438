import Axios, { CancelTokenSource } from 'axios'
import { DatosClienteProps, DatosProductoProps } from '../App';

const imprimir = async (factura: string, origenId: number, comercioId: number) => {
    const paramGarex = {
        Factura: factura,
        OrigenId: origenId,
        ComercioId: comercioId
    }
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/garex/imprimir", paramGarex,
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};

export default imprimir;