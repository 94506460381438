import React, { forwardRef } from 'react'
import styled from '@emotion/styled'



interface SegButtonProps {
  type: "button" | "submit"
  label: string,
  onClick?: () => void,
  disabled?: boolean
  bgColor?: string
  textColor?: string
  borderColor?: string
  hoverBgColor?: string
  hoverTextColor?: string
  hoverBorderColor?: string
  disabledBgColor?: string
  disabledTextColor?: string
  activeBgColor?: string
  activeTextColor?: string
  className?: string
  loading?: boolean
  idButton?: string
}

const Button = styled.button<{ bgColor: string, textColor: string, borderColor: string, hoverBgColor: string, hoverTextColor: string, hoverBorderColor: string, disabledBgColor: string, disabledTextColor: string, activeBgColor: string, activeTextColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  border-color: ${({ borderColor }) => borderColor};
  min-width: 172px;


  &:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }


  &:disabled {
    background-color: ${({ disabledBgColor }) => disabledBgColor};
    color: ${({ disabledTextColor }) => disabledTextColor};
    border-color: ${({ disabledBgColor }) => disabledBgColor};
  }


  &:active {
    background-color: ${({ activeBgColor }) => activeBgColor} !important;
    color: ${({ activeTextColor }) => activeTextColor} !important;
    border-color: ${({ activeBgColor }) => activeBgColor} !important;
  }

  &:focus {
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ textColor }) => textColor};
    border-color: ${({ borderColor }) => borderColor};
  }

  &:focus:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

`;

export const SegButton = forwardRef<HTMLButtonElement, SegButtonProps>(
  (
    {
      type,
      label,
      onClick,
      disabled = true,
      bgColor = '#2C7DFD',
      textColor = '#fff',
      borderColor = '#2C7DFD',
      hoverBgColor = '#0b5ed7',
      hoverTextColor = '#fff',
      hoverBorderColor = '#0a53be',
      disabledBgColor = '#a5a5a5',
      disabledTextColor = '#fff',
      activeBgColor = '#0a58ca',
      activeTextColor = '#fff',
      className,
      loading = false,
      idButton,
      ...rest
    },
    ref) => {

    return (
      <Button id={idButton} ref={ref} disabled={disabled || loading} type={type} onClick={() => { onClick && onClick() }} className={`btn ${className}`} bgColor={bgColor} textColor={textColor} borderColor={borderColor} hoverBgColor={hoverBgColor} hoverTextColor={hoverTextColor} hoverBorderColor={hoverBorderColor} disabledBgColor={disabledBgColor} disabledTextColor={disabledTextColor} activeBgColor={activeBgColor} activeTextColor={activeTextColor}  >
        {!loading && label}
        {loading &&
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        }
      </Button>
    )
  }
)