import { useLocation } from 'react-router-dom';
import { SegHeaderLogo } from './components/SegHeaderLogo/SegHeaderLogo';
import logo from "./assets/img/logo_garex.png"
import logoPartner from "./assets/img/logo_santander_consumer.png"

export const Gracias = () => {
  // Obtener el estado de la redirección
  const location = useLocation();
  const { titulo, texto } = location.state as { titulo: string, texto: string }; // Define el tipo de los datos esperados

  return (
    <>
      <div className="container sticky-top pt-3 pb-3 bg-white" style={{ maxWidth: "100%" }}>
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
            <SegHeaderLogo img={logo} imgPartner={""} altPartner='' alt="Garex Assist" width="160px" />
          </div>
        </div>
      </div>
      <div className="container-fluid vh-95 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h1 className="mb-4">{titulo}</h1>
          <p>{texto}</p>
        </div>
      </div>
    </>
  );
};
