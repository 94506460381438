import styled from "@emotion/styled";
import React from "react";

interface SegFooterProps {
  img?: string;
  alt?: string;
  imgWidth?: number;
  textWidth?: number;
  text: string;
  textColor?: string;
  fontSize?: number;
  fontWeight?: "normal" | 500 | "bold";
  lineHeight?: number;
  backgroundColor?: string;
  textAlign?: "left" | "center" | "right" | "justify";
  className?: string;
  padding?: number;
  textAdicional?: string;
  linkAdicional?: string;
}

export const SegFooter = ({
  img,
  alt = "segurarse",
  imgWidth = 99,
  text,
  textWidth,
  textColor = "#FFFFFF",
  fontSize = 14,
  fontWeight = "normal",
  lineHeight = 1.25,
  backgroundColor = "#363636",
  textAlign = "center",
  className,
  padding = 15,
  textAdicional,
  linkAdicional
}: SegFooterProps) => {
  const StyledFooter = styled.footer`
    padding: ${padding}px;
    display: flex;
    background-color: ${backgroundColor} !important;
    flex-direction: column;
  `;

  const StyledImg = styled.img`
    margin-right: 1.5rem !important;
    width: ${imgWidth}px;
  `;
  const StyledDiv = styled.div`
    color: ${textColor} !important;
    font-weight: ${fontWeight} !important;
    font-size: ${fontSize}px !important;
    line-height: ${lineHeight} !important;
    text-align: ${textAlign} !important;
  `;

  const StyledSpan = styled.span`
    display: inline-block;
    width: ${textWidth}px;
  `;

  const StyledSpanAdicional = styled.div`
    display: block;
    margin: 7px auto 0;
    & a {
      color: ${textColor} !important;
      font-weight: ${fontWeight} !important;
      font-size: ${fontSize}px !important;
      line-height: ${lineHeight} !important;
      text-align: ${textAlign} !important;
      text-decoration: underline;
    }
    
  `

  return (
    <StyledFooter className={className} >
      <StyledDiv>
        {img !== "" ? <StyledImg src={img} alt={alt} /> : ""}
        <StyledSpan>{text}</StyledSpan>
      </StyledDiv>
      {textAdicional && linkAdicional &&
        <StyledSpanAdicional>
          <a href={linkAdicional} target="_blank">{textAdicional}</a>
        </StyledSpanAdicional>
      }
    </StyledFooter>
  );
};
