import React from 'react'
import styled from '@emotion/styled'

interface SegH1Props {
    title: string
    textColor?: string
    fontSize?: number
    fontWeight?: "bold" | "normal" | 500 | 700
    className?:string
}




export const SegH1 = ({ title,
    textColor = "#000",
    fontSize = 24,
    fontWeight = "bold",
    className
}: SegH1Props) => {

    const H1 = styled.legend`
    color: ${textColor} !important;
    font-weight:${fontWeight} !important;
    font-size:${fontSize}px !important;
    line-height:1;
    `


    return (
        <H1 className={className}>{title}</H1>
    )
}
