import { create } from 'zustand';
import { Comercio, Origen, Sucursal } from '../App';

export interface DatosIniciales {
  origen: Origen;
  comercio: Comercio;
  sucursal: Sucursal;
  ambiente: string;
}

interface StoreState {
  datos: DatosIniciales | null;
  guardarDatos: (nuevosDatos: Partial<DatosIniciales>) => void; // Partial permite actualizar una parte del objeto
  limpiarDatos: () => void;
}

const useStore = create<StoreState>((set) => ({
  datos: null,
  guardarDatos: (nuevosDatos) => set((state) => ({
    datos: state.datos
      ? { ...state.datos, ...nuevosDatos } // Si datos ya existe, mezcla los nuevos datos con los existentes
      : { ...nuevosDatos } as DatosIniciales, // Si no existe, crea un nuevo objeto
  })),
  limpiarDatos: () => set({ datos: null }),
}));

export default useStore;