import React from "react";
import styled from "@emotion/styled";

interface SegHeaderLogoProps {
  img: string;
  alt: string;
  width?: string;
  className?: string;
  imgPartner?: string
  altPartner?: string;
}

export const SegHeaderLogo = ({
  img,
  alt,
  width = "100%",
  className,
  imgPartner,
  altPartner
}: SegHeaderLogoProps) => {
  const Img = styled.img`
    width: ${width};
  `;

  return (
    <div>
      <Img src={img} alt={alt} width={width} className={className} />
      {imgPartner && 
        <Img style={{borderLeft: "1px solid grey", paddingLeft: "15px", marginLeft: "10px"}} src={imgPartner} alt={altPartner} width={width} className={className} />
      }
    </div>
  );
};
