import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { DatosProductoProps, ErrorCustom } from "../../App";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SegInput } from "../SegInput/SegInput";
import { SegButton } from "../SegButton/SegButton";
import { SegSelect, SegSelectOption } from "../SegSelect/SegSelect";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface FormValues {
    setStep: Dispatch<SetStateAction<number>>;
    setDatosProducto: Dispatch<SetStateAction<DatosProductoProps | null>>;
    datosProducto: DatosProductoProps | null;
    spinner: boolean,
    errores: ErrorCustom[],
    setCaptchaToken: (token: string | null) => void;
    emitirSpinner: (setter: boolean) => void;
}

export const DatosProducto = ({
    setStep,
    setDatosProducto,
    datosProducto,
    spinner,
    errores,
    setCaptchaToken,
    emitirSpinner
}: FormValues) => {
    const { handleSubmit, control, setValue, formState, trigger, setError } = useForm<DatosProductoProps>();
    const methods = useForm<DatosProductoProps>();
    const [tipoCobertura, setTipoCobertura] = useState<SegSelectOption>();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const opcionesCoberturas = [
        { value: "191", label: "12 meses" }
        //{ value: "192", label: "24 meses" },
    ];

    const onSubmit: SubmitHandler<DatosProductoProps> = (values: DatosProductoProps) => {
        if (!executeRecaptcha) {
            alert("reCAPTCHA no está listo");
            return;
        } else {
            executeRecaptcha('submit_form').then((res: any) => {
                emitirSpinner(true);
                setCaptchaToken(res);
                setDatosProducto({ ...values })
            })
        }
    };

    useEffect(() => {
        setValue('productoGarantiaExtendida', Number.parseInt(tipoCobertura?.value ?? "0"), { shouldValidate: true })
    }, [tipoCobertura])

    useEffect(() => {
        errores.forEach(element => {
            switch (element.campoNombre) {
                case "productoGarantiaFabrica":
                    setError(element.campoNombre, {
                        type: "manual",
                        message: element.error,
                    });
                    break;
                default:
                    break;
            }
        });
    }, [errores])

    useEffect(() => {
        setValue("productoMarca", datosProducto?.productoMarca ? datosProducto?.productoMarca : "")
        setValue("productoModelo", datosProducto?.productoModelo ? datosProducto?.productoModelo : "")
        setValue("productoPrecio", datosProducto?.productoPrecio ? datosProducto?.productoPrecio : 0)
        setValue("productoFactura", datosProducto?.productoFactura ? datosProducto?.productoFactura : "")
        setValue("productoGarantiaFabrica", datosProducto?.productoGarantiaFabrica ? datosProducto?.productoGarantiaFabrica : "")
        if (datosProducto?.productoGarantiaExtendida) {
            setTipoCobertura({value: datosProducto?.productoGarantiaExtendida.toString(), label: datosProducto?.productoGarantiaExtendida.toString()});
        }
    }, []);

    return (
        <div className="bg-gray-light">
            <div className="container">
                <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <Controller
                            name="productoMarca"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá la marca del producto",
                                    pattern: (value) =>
                                        /^[\w\s\W]+$/.test(value) ||
                                        "Marca inválida",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Marca de producto"
                                    name="productoMarca"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Samsung"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("productoMarca", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("productoMarca");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.productoMarca
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.productoMarca
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="productoModelo"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el modelo del producto",
                                    pattern: (value) =>
                                        /^[\w\s\W]+$/.test(value) ||
                                        "Modelo inválido",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Modelo de producto"
                                    name="productoModelo"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: Samsung"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("productoModelo", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("productoModelo");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.productoModelo
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.productoModelo
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="productoPrecio"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el precio del producto",
                                    pattern: (value) =>
                                        /^-?\d+(\.\d{2})?$/.test(value.toString()) ||
                                        "Precio inválido",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Precio del producto"
                                    name="productoPrecio"
                                    type="text"
                                    width={50}
                                    value={field.value ? field.value.toString() : ""}
                                    placeHolder="Ej: 20000"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("productoPrecio", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("productoPrecio");
                                    }}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.productoPrecio
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.productoPrecio
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="productoFactura"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá el número de factura del producto",
                                    pattern: (value) =>
                                        /^\d{12,14}$/.test(value) ||
                                        "Factura inválida.",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Número factura"
                                    name="productoFactura"
                                    type="number"
                                    width={50}
                                    placeHolder="Ej: 00760000006754"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("productoFactura", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("productoFactura");
                                    }}
                                    value={field.value}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.productoFactura
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.productoFactura
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <Controller
                            name="productoGarantiaFabrica"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => !!value || "Ingresá la cantidad de meses de la garantía de fábrica",
                                    pattern: (value) =>
                                        /^(3|6|9|12|24)$/.test(value.toString()) ||
                                        "Garantía de fábrica inválida",
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    className="mw-sm-450 m-auto mb-4"
                                    label="Garantía de fábrica"
                                    name="productoGarantiaFabrica"
                                    type="text"
                                    width={50}
                                    placeHolder="Ej: 12"
                                    value={field.value ? field.value.toString() : undefined}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValue("productoGarantiaFabrica", e.target.value); // Actualiza el valor en React Hook Form
                                        trigger("productoGarantiaFabrica");
                                    }}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${formState.errors.productoGarantiaFabrica
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.productoGarantiaFabrica
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset>
                        <FormProvider {...methods}>
                            <div style={{ width: "50%" }} className="mw-sm-450 m-auto mb-4">
                                <label className='mb-2'>Seleccioná la cobertura</label>
                                <SegSelect
                                    width='100%'
                                    className=""
                                    selected={tipoCobertura}
                                    setSelected={setTipoCobertura}
                                    name="productoGarantiaExtendida"
                                    options={opcionesCoberturas}
                                    borderColor={`${formState.errors.productoGarantiaExtendida
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${formState.errors.productoGarantiaExtendida
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                    openDefault={false}
                                    buscador={false}
                                    placeholder='Seleccionar'
                                />
                            </div>
                        </FormProvider>
                    </fieldset>
                    <div className="mx-auto mb-4" style={{ width: "172px" }}>
                        <SegButton
                            label="Emitir"
                            type="submit"
                            className="btn btn-primary"
                            loading={spinner}
                            disabled={
                                !formState.isValid // Verifica si el formulario es válido
                            }
                        />
                    </div>
                </form>
            </div>
        </div>
    )
};