import React, { useEffect, useRef, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useFormContext } from "react-hook-form";


export interface SegSelectOption {
  value: string;
  label: string;
}

interface SegSelectProps {
  options: SegSelectOption[];
  width?: string;
  placeholder?: string;
  isMulti?: boolean;
  borderColor?: string;
  hoverBorderColor?: string;
  focusBorderColor?: string;
  selected?: SegSelectOption | null;
  setSelected?: (selected: SegSelectOption) => void;
  className?: string;
  id?: string;
  name: string;
  disabled?: boolean;
  loading?: boolean;
  openDefault?: boolean;
  handleSubmit?: (value:SegSelectOption) => void
  autoFoco?: boolean;
  buscador?: boolean;
}

export const SegSelect = ({
  options = [],
  width = "18em",
  placeholder = "Buscar...",
  isMulti = false,
  borderColor = "black",
  hoverBorderColor = "black",
  focusBorderColor = "#2C7DFD",
  selected = null,
  setSelected,
  className,
  name,
  id,
  disabled = false,
  loading = false,
  openDefault = true,
  handleSubmit,
  autoFoco = false,
  buscador = true
}: SegSelectProps) => {
  const { setValue } = useFormContext(); // Obtén la instancia del formulario usando useFormContext
  const [isOpen, setIsOpen] = useState<boolean>()
  const handleChange = (option: any) => {
    setValue(name, option.value); // Usa setValue para actualizar el valor del campo
    setSelected && setSelected(option);
    setIsOpen(false)
    if (handleSubmit) {
      handleSubmit(option)
    }
  };
  const toggleMenu = () => {
    setIsOpen(current => !current)
  };

  useEffect(() => {

    setIsOpen(openDefault)
  }, [openDefault])

  const style: StylesConfig = {
    menu: (base: any) => ({
      ...base,
      scrollbarColor: "white",
    }),
    control: (base: any) => ({
      ...base,
      boxShadow: "none",
      borderColor: borderColor,
      width: width,
      "&:hover": {
        borderColor: hoverBorderColor,
      },
      "&:focus-within": {
        borderColor: focusBorderColor,
      },
      "&::-webkit-scrollbar": {
        width: 12,
        borderRadius: 20,
      },
    }),
    option: (base: any) => ({
      ...base,
    }),
    input: (base: any) => ({
      gridArea: "1/1/2/3",
    }),
  };

  const isOptionDisabled = (option: SegSelectOption) => {
    return option.value === 'separator';
  };

  return (
    
    <Select
      className={className}
      isMulti={isMulti}
      styles={style}
      options={options ? options : []}
      value={selected}
      onChange={handleChange}
      isSearchable={buscador}
      placeholder={placeholder}
      id={id}
      name={name}
      isDisabled={disabled}
      isLoading={loading}
      menuIsOpen={isOpen}
      onMenuOpen={toggleMenu}
      onMenuClose={() => { setIsOpen(false) }}
      isOptionDisabled={(option: any) => isOptionDisabled(option as SegSelectOption)}
      autoFocus={autoFoco}
    />
  );
};
