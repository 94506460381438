import Axios from 'axios'

export const origenes = async () => {
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/garex/origenes",
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};

export const comercios = async (origenId: number) => {
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/garex/comercios/"+origenId,
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};

export const sucursales = async (comercioId: number) => {
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/garex/sucursales/"+comercioId,
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};