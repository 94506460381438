import Axios from "axios";

export const ObtenerCarta = async (cartaId: string) => {
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/garex/carta/" + cartaId,
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};

export const ConfirmarCarta = async (cartaId: string) => {
    const headers = {
        'content-type': 'application/json',
    }
    return await Axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/garex/carta", { id: cartaId},
        {
            headers: headers,
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
                // console.log('Solicitud cancelada', error.message);
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
}