import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Error = () => {
    return (
        <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h1 className="mb-4">Error</h1>
                <p>Combinación de datos incorrecta</p>
            </div>
        </div>
    );
};

export default Error;